﻿:root {
  /* GLOBAL */
  --sdw-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  /* BUTTON */
  --sdw-button-top-position: 20px;
  --sdw-button-right-position: 20px;
  --sdw-button-bottom-position: 20px;
  --sdw-button-left-position: 20px;
  --sdw-button-width-height: 60px;
  --sdw-button-background: radial-gradient(100% 100% at 100% 0, #686868 0, #000000 100%);
  --sdw-button-mask-size: -8px;
  --sdw-button-mask-opacity: .2;
  --sdw-button-mask-background: var(--sdw-button-background);
  --sdw-button-pulse-border-color: gray;
  --sdw-button-chat-image-color: #fff;
  --sdw-button-chat-image-width: 30px;
  --sdw-button-chat-image-height: 30px;
  --sdw-button-close-image-color: #fff;
  --sdw-button-close-image-width: 30px;
  --sdw-button-close-image-height: 30px;
  --sdw-button-social-image-color: #fff;
  --sdw-button-social-background: gray;

  --sdw-button-flat-border-radius: 10px 35px 0 0;

  /* POPUP */
  --sdw-button-popup-width: 250px;
  --sdw-button-popup-border-color: var(--sdw-button-pulse-border-color);
  --sdw-button-popup-background: #fff;
  --sdw-button-popup-image-width-height: 42px;
  --sdw-button-popup-name-color: #000;
  --sdw-button-popup-description-color: #424956;

  /* WIDGET */
  --sdw-scrollbar-colors: #202020 #d8d8d8;
  --sdw-widget-width: 380px;
  --sdw-widget-height: 600px;
  --sdw-widget-border-size: 1px;
  --sdw-widget-border-color: rgba(0, 0, 0, .4);
  --sdw-widget-border-radius: 10px 35px 10px 10px;
  --sdw-widget-border-radius-container: 7px 32px 0 0;
  --sdw-widget-background: #fff;

  --sdw-widget-inline-box-shadow: 0 20px 40px 8px var(--sdw-widget-border-color);
  --sdw-widget-inline-border-radius: var(--sdw-widget-border-radius);
  --sdw-widget-inline-padding: 10px;

  --sdw-widget-head-background: linear-gradient(95deg, #000 10%, rgb(66, 72, 103) 80%);
  --sdw-widget-head-title-color: #fff;
  --sdw-widget-head-operator-width-height: 40px;
  --sdw-widget-head-operator-name-color: #f0f1f1;
  --sdw-widget-head-operator-description-color: #f0f1f1;
  --sdw-widget-head-operator-description-opacity: .5;

  --sdw-widget-body-background: var(--sdw-widget-background);
  --sdw-widget-body-received-border-color: rgba(0, 0, 0, .07);
  --sdw-widget-body-received-background: #f1f0f0;
  --sdw-widget-body-received-text-color: #222d38;
  --sdw-widget-body-sent-border-color: rgba(0, 0, 0, .07);
  --sdw-widget-body-sent-background: #ff7a1b;
  --sdw-widget-body-sent-text-color: #fff;
  --sdw-widget-body-error-border-color: rgba(0, 0, 0, .07);
  --sdw-widget-body-error-background: #ff0000;
  --sdw-widget-body-error-text-color: #222d38;
  --sdw-widget-body-operator-name-color: #6F7880;

  --sdw-widget-foot-border-color: #d8dee3;
  --sdw-widget-foot-background: #fff;
  --sdw-widget-foot-text-color: #222;
  --sdw-widget-foot-sent-button-border-radius: 50%;
  --sdw-widget-foot-sent-button-color: #f1f0f0;
  --sdw-widget-foot-sent-button-active-color: #f45821;

  --sdw-widget-copyright-background: #fff;
  --sdw-widget-copyright-padding: 0 25px;
  --sdw-widget-copyright-text-color: #7d7d7d;
  --sdw-widget-copyright-text-size: 13px;
  --sdw-widget-copyright-image-width: 80px;
}