﻿// CHAT WIDGET
.startduck-ai-chat-widget-wrapper {
  display: none;
  position: fixed;
  width: var(--sdw-widget-width);
  height: var(--sdw-widget-height);
  min-height: 500px;
  z-index: 99999 !important;
  -webkit-transition: -webkit-box-shadow .4s;
  -o-transition: box-shadow .4s;
  transition: box-shadow .4s, -webkit-box-shadow .4s;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: 0 20px 40px 8px var(--sdw-widget-border-color);
  box-shadow: 0 20px 40px 8px var(--sdw-widget-border-color);
  -webkit-font-smoothing: antialiased;
  border-radius: var(--sdw-widget-border-radius);
  border: var(--sdw-widget-border-size) solid var(--sdw-widget-border-color);
  user-select: auto;
  -webkit-tap-highlight-color: transparent;

  &.startduck-ai-chat-widget-show {
    display: block;
    -webkit-animation: startduck-ai-chat-show .3s ease-out forwards;
    animation: startduck-ai-chat-show .3s ease-out forwards;
  }

  &.startduck-ai-chat-widget-slide {
    bottom: 0;
    display: block;
    -webkit-transition: height .2s;
    -moz-transition: height .2s;
    -o-transition: height .2s;
    transition: height .2s;
  }

  .startduck-ai-chat-widget-container {
    -webkit-animation: startduck-ai-chat-widget-container-show .3s ease-out forwards;
    animation: startduck-ai-chat-widget-container-show .3s ease-out forwards;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: var(--sdw-widget-background);
    border-radius: var(--sdw-widget-border-radius-container);
    overflow: hidden;

    .startduck-ai-chat-widget-hide-btn {
      position: absolute;
      cursor: pointer;
      width: 28px;
      height: 28px;
      opacity: .85;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20transform%3D%22translate(2%202)%22%3E%0A%20%20%20%20%20%20%20%20%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2212%22%20fill%3D%22%23FFF%22%20opacity%3D%221%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2212.75%22%20stroke%3D%22%23222D38%22%20stroke-width%3D%221.5%22%20opacity%3D%221%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23222D38%22%20opacity%3D%221%22%20transform%3D%22translate(6%206)%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Crect%20width%3D%221.611%22%20height%3D%2213.9%22%20x%3D%225.435%22%20y%3D%22-.941%22%20rx%3D%22.806%22%20transform%3D%22rotate(45%206.24%206.01)%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Crect%20width%3D%221.611%22%20height%3D%2213.9%22%20x%3D%225.435%22%20y%3D%22-.941%22%20rx%3D%22.806%22%20transform%3D%22scale(-1%201)%20rotate(45%200%20-9.058)%22%2F%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
      border-radius: 50%;

      &:hover {
        opacity: 1;
        -webkit-animation: startduck-ai-chat-widget-spin 4s linear infinite;
        animation: startduck-ai-chat-widget-spin 4s linear infinite;
      }
    }

    .startduck-ai-chat-widget-hide-btn-minimize {
      position: absolute;
      cursor: pointer;
      top: 5px;
      right: 15px;
      border-bottom: 2px solid white;
      width: 15px;
      height: 15px;
      z-index: 4;
    }

    .startduck-ai-chat-widget-head-wrap {
      background: var(--sdw-widget-head-background);
      /*border-top-left-radius: 10px;
      border-top-right-radius: 35px;*/
      overflow: hidden;
      cursor: move;

      .startduck-ai-chat-widget-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        //padding: 0 0 0 3px;
        padding-left: 8px;
        padding-right: 32px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 60px;
        height: 70px;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        background-position: 0 0;
        background-repeat: repeat;
        user-select: none;
        -webkit-user-select: none;

        .startduck-ai-chat-widget-head-container {
          display: flex;
          align-items: center;
          width: 100%;

          .startduck-ai-chat-widget-head-wrap-logo {
            margin: 10px;
            display: flex;

            &.default::before {
              content: ':';
              color: #fff;
              align-self: end;
              font: bold 30px var(--sdw-font-family);
              margin-right: 2px;
            }

            &.operator {
              position: relative;

              &.online .startduck-ai-chat-widget-head-online {
                width: 5px;
                height: 5px;
                position: absolute;
                right: 10px;
                z-index: 1;
                border-radius: 50%;
                background-color: #18c139;
                border: 2px solid rgb(47, 50, 74);
              }

              .startduck-ai-chat-widget-head-logo {
                border-radius: 50%;
              }

              &.startduck-ai-chat-widget-head-logo-blink {
                border-radius: 50%;
                overflow: hidden;

                &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  width: 140%;
                  height: 100%;
                  top: 120%;
                  left: -20%;
                  opacity: 0.6;
                  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #ffffff 40%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0));
                  -webkit-transform: rotate(20deg) translateZ(0);
                  -moz-transform: rotate(20deg) translateZ(0);
                  -ms-transform: rotate(20deg) translateZ(0);
                  -o-transform: rotate(20deg) translateZ(0);
                  transform: rotate(20deg) translateZ(0);
                  -webkit-animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
                  -moz-animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
                  -ms-animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
                  -o-animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
                  animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
                  will-change: transform;
                }
              }
            }

            .startduck-ai-chat-widget-head-logo {
              width: var(--sdw-widget-head-operator-width-height);
              height: var(--sdw-widget-head-operator-width-height);
            }
          }

          .startduck-ai-chat-widget-head-title {
            color: var(--sdw-widget-head-title-color);
            font: 25px var(--sdw-font-family);
            font-weight: 600;
            max-width: 100%;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -ms-flex-negative: 1;
            flex-shrink: 1;
          }

          .startduck-ai-chat-widget-head-operator-container {
            font: 14px/18px var(--sdw-font-family);
            -ms-flex-positive: 1;
            flex-grow: 1;
            -ms-flex-negative: 1;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .startduck-ai-chat-widget-head-operator-name {
              color: var(--sdw-widget-head-operator-name-color);
              font: 15px/18px var(--sdw-font-family);
              margin-bottom: 4px;
            }

            .startduck-ai-chat-widget-head-operator-description {
              color: var(--sdw-widget-head-operator-description-color);
              font: 13px/14px var(--sdw-font-family);
              opacity: var(--sdw-widget-head-operator-description-opacity);
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .startduck-ai-chat-widget-head-debug {
            position: absolute;
            right: 40px;
            top: 17px;
            width: 34px;
            height: 34px;
            outline: none;
            -webkit-tap-highlight-color: transparent;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.2);

            svg {
              cursor: pointer;
              width: 34px;
              height: 34px;
              color: rgba(255, 255, 255, .5);

              &:hover {
                -webkit-animation: startduck-ai-chat-widget-spin 2s linear infinite;
                animation: startduck-ai-chat-widget-spin 2s linear infinite;
              }
            }
          }

          .startduck-ai-chat-widget-head-light {
            position: absolute;
            right: 0;
            top: 0;
            width: 35px;
            height: 35px;
            border-radius: 0 35px;
            background: #ff7a1b;
            border: 1px solid rgba(0, 0, 0, 0.5);
            border-right: 0;
            border-top: 0;
          }
        }
      }
    }

    .startduck-ai-chat-widget-body-wrap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      overflow: hidden;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: var(--sdw-widget-body-background);
      -webkit-overflow-scrolling: touch;

      &.startduck-ai-chat-widget-body-has-messages {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        display: initial;
      }

      .startduck-ai-chat-widget-body-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .startduck-ai-chat-widget-body-pin-column {
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          overflow: hidden;
          -webkit-overflow-scrolling: touch;

          .startduck-ai-chat-widget-body-pin-bottom {
            position: relative;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -ms-flex-negative: 1;
            flex-shrink: 1;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            overflow: hidden;
            -webkit-overflow-scrolling: touch;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            .startduck-ai-chat-widget-body-column {
              position: relative;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
              width: 100%;
              overflow: hidden;
              -webkit-animation: startduck-ai-chat-widget-messages .2s ease-in-out;
              animation: startduck-ai-chat-widget-messages .2s ease-in-out;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;

              .startduck-ai-chat-widget-body-items {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                height: 100%;
                overflow: hidden;

                .startduck-ai-chat-widget-body-group {
                  margin: 24px 0;
                  text-align: center;
                  color: #697582;
                }

                .startduck-ai-chat-widget-body-item {
                  -webkit-overflow-scrolling: touch;
                  overflow-y: auto;
                  overflow-x: hidden;
                  width: 100%;
                  scrollbar-width: thin;
                  scrollbar-color: var(--sdw-scrollbar-colors);
                  padding-bottom: 20px;

                  .startduck-ai-chat-widget-body-message-wrap {
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -ms-flex-wrap: nowrap;
                    flex-wrap: nowrap;
                    -webkit-box-align: start;
                    -ms-flex-align: start;
                    align-items: flex-start;
                    margin: 10px 0 5px;
                    outline: none;
                    -webkit-appearance: none;

                    .startduck-ai-chat-widget-body-message-operator-name {
                      position: absolute;
                      top: -22px;
                      color: var(--sdw-widget-body-operator-name-color);
                      font-size: 14px;

                      &.startduck-ai-chat-widget-body-message-operator-name-margin-left {
                        margin-left: 50px;
                      }
                    }

                    .startduck-ai-chat-widget-body-message-operator {
                      align-self: center;

                      svg,
                      img {
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        margin-right: 5px;
                      }
                    }

                    .startduck-ai-chat-widget-body-message {
                      max-width: 250px;
                      font: 14px/19px var(--sdw-font-family);
                      display: block;
                      padding: 12px 16px;
                      border-radius: 12px;
                      -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
                      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
                      word-break: break-word;
                      -webkit-transition: background-color .5s, color .5s, font-size .3s;
                      -o-transition: background-color .5s, color .5s, font-size .3s;
                      transition: background-color .5s, color .5s, font-size .3s;

                      .startduck-ai-chat-widget-body-message-text {
                        p {
                          margin: 0;
                        }

                        code {
                          white-space: pre-wrap;
                        }
                      }

                      .startduck-ai-chat-widget-body-message-params {
                        float: right;
                        display: block;
                        white-space: nowrap;
                        position: relative;
                        padding-right: 10px;
                        margin-right: -10px;

                        .startduck-ai-chat-widget-body-message-date {
                          display: inline-block;
                          font: 11px/18px var(--sdw-font-family);
                          text-align: right;
                          padding-top: 1px;
                          padding-left: 10px;
                        }
                      }
                    }

                    &.dialog {
                      -webkit-box-direction: reverse;
                      -ms-flex-direction: row-reverse;
                      flex-direction: row-reverse;

                      .startduck-ai-chat-widget-body-message {
                        border-radius: 20px;
                        background: #fff;
                        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 0 6px -2px rgba(0, 0, 0, .05), inset 0 0 0 1px var(--sdw-widget-body-sent-background);
                        color: var(--sdw-widget-body-sent-background);
                        transition: all .5s;

                        &:hover {
                          background: linear-gradient(315deg, #f75f22, #fe812b);
                          color: #fff;
                          cursor: pointer;
                          outline: none;
                        }

                        .startduck-ai-chat-widget-body-message-params {
                          display: none;
                        }
                      }
                    }

                    &.received {
                      &.has-operator {
                        margin-top: 32px;
                      }

                      .startduck-ai-chat-widget-body-message {
                        background: var(--sdw-widget-body-received-background);
                        color: var(--sdw-widget-body-received-text-color);
                        border: 1px solid var(--sdw-widget-body-received-border-color);
                      }

                      &.loading .startduck-ai-chat-widget-body-message-text p {
                        clip-path: inset(0 1ch 0 0);
                        animation: startduck-ai-chat-widget-loading 1.5s steps(4) infinite;
                      }

                      &.startduck-ai-chat-widget-body-message-with-tail {
                        &::after {
                          content: '';
                          position: absolute;
                          width: 15px;
                          height: 12px;
                          background-color: var(--sdw-widget-body-received-background);
                          left: 1px;
                          bottom: 1px;
                        }

                        .startduck-ai-chat-widget-body-message {
                          border-bottom-left-radius: 0;
                          -webkit-box-shadow: none;
                          -moz-box-shadow: none;
                          box-shadow: none;
                        }

                        .startduck-ai-chat-widget-body-message-tail {
                          position: absolute;
                          left: -20px;
                          bottom: 7px;
                          width: 1px;
                          height: 1px;

                          &::before {
                            content: "";
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            height: 11px;
                            border-right: 30px solid var(--sdw-widget-body-received-background);
                            -webkit-box-shadow: 0 0 0 1px var(--sdw-widget-body-received-border-color);
                            -moz-box-shadow: 0 0 0 1px var(--sdw-widget-body-received-border-color);
                            box-shadow: 0 0 0 1px var(--sdw-widget-body-received-border-color);
                            -webkit-border-bottom-right-radius: 80px 50px;
                            -moz-border-radius-bottomright: 80px 50px;
                            border-bottom-right-radius: 80px 50px;
                            -webkit-transform: translate(0, -2px);
                            -moz-transform: translate(0, -2px);
                            -ms-transform: translate(0, -2px);
                            -o-transform: translate(0, -2px);
                            transform: translate(0, -2px);
                          }

                          &::after {
                            content: "";
                            position: absolute;
                            bottom: -11px;
                            left: 0;
                            width: 30px;
                            height: 13px;
                            background: var(--sdw-widget-background);
                            -webkit-border-bottom-right-radius: 40px 50px;
                            -moz-border-radius-bottomright: 40px 50px;
                            border-bottom-right-radius: 40px 50px;
                            -webkit-transform: translate(-10px, -2px);
                            -moz-transform: translate(-10px, -2px);
                            -ms-transform: translate(-10px, -2px);
                            -o-transform: translate(-10px, -2px);
                            transform: translate(-10px, -2px);
                            -webkit-box-shadow: 1px 0 0 0 var(--sdw-widget-body-received-border-color);
                            -moz-box-shadow: 1px 0 0 0 var(--sdw-widget-body-received-border-color);
                            box-shadow: 1px 0 0 0 var(--sdw-widget-body-received-border-color);
                          }
                        }
                      }
                    }

                    &.sent {
                      -webkit-box-direction: reverse;
                      -ms-flex-direction: row-reverse;
                      flex-direction: row-reverse;

                      .startduck-ai-chat-widget-body-message {
                        background: var(--sdw-widget-body-sent-background);
                        color: var(--sdw-widget-body-sent-text-color);
                        border: 1px solid var(--sdw-widget-body-sent-border-color);
                      }

                      &.startduck-ai-chat-widget-body-message-with-tail {
                        &::after {
                          content: '';
                          position: absolute;
                          width: 10px;
                          height: 10px;
                          background-color: var(--sdw-widget-body-sent-background);
                          right: 1px;
                          bottom: 1px;
                        }

                        .startduck-ai-chat-widget-body-message {
                          border-bottom-right-radius: 0;
                          -webkit-box-shadow: none;
                          -moz-box-shadow: none;
                          box-shadow: none;
                        }

                        .startduck-ai-chat-widget-body-message-tail {
                          position: absolute;
                          right: -20px;
                          bottom: 7px;
                          width: 1px;
                          height: 1px;
                          -webkit-transform: rotateY(180deg);
                          -moz-transform: rotateY(180deg);
                          transform: rotateY(180deg);

                          &::before {
                            content: "";
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            height: 10px;
                            border-right: 30px solid var(--sdw-widget-body-sent-background);
                            -webkit-box-shadow: 0 0 0 1px var(--sdw-widget-body-sent-border-color);
                            -moz-box-shadow: 0 0 0 1px var(--sdw-widget-body-sent-border-color);
                            box-shadow: 0 0 0 1px var(--sdw-widget-body-sent-border-color);
                            -webkit-border-bottom-right-radius: 80px 50px;
                            -moz-border-radius-bottomright: 80px 50px;
                            border-bottom-right-radius: 80px 50px;
                            -webkit-transform: translate(0, -2px);
                            -moz-transform: translate(0, -2px);
                            -ms-transform: translate(0, -2px);
                            -o-transform: translate(0, -2px);
                            transform: translate(0, -2px);
                          }

                          &::after {
                            content: "";
                            position: absolute;
                            bottom: -11px;
                            left: 0;
                            width: 30px;
                            height: 13px;
                            background: var(--sdw-widget-background);
                            -webkit-border-bottom-right-radius: 40px 50px;
                            -moz-border-radius-bottomright: 40px 50px;
                            border-bottom-right-radius: 40px 50px;
                            -webkit-transform: translate(-10px, -2px);
                            -moz-transform: translate(-10px, -2px);
                            -ms-transform: translate(-10px, -2px);
                            -o-transform: translate(-10px, -2px);
                            transform: translate(-10px, -2px);
                            -webkit-box-shadow: 1px 0 0 0 var(--sdw-widget-body-sent-border-color);
                            -moz-box-shadow: 1px 0 0 0 var(--sdw-widget-body-sent-border-color);
                            box-shadow: 1px 0 0 0 var(--sdw-widget-body-sent-border-color);
                          }
                        }
                      }
                    }

                    &.error {
                      .startduck-ai-chat-widget-body-message {
                        background: var(--sdw-widget-body-error-background);
                        color: var(--sdw-widget-body-error-text-color);
                        border: 1px solid var(--sdw-widget-body-error-border-color);
                      }
                    }

                    &.dialog, &.received, &.sent, &.error {
                      margin-left: 15px;
                      margin-right: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .startduck-ai-chat-widget-foot-wrap {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
      flex: 0 0 70px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      max-height: 200px;
      min-height: 70px;

      .startduck-ai-chat-widget-foot-resize {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0uNS41SDhtLTcuNSAySDgiIHN0cm9rZT0iIzk3OTc5NyIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PC9zdmc+);
        background-position: center center;
        background-repeat: no-repeat;
        height: 10px;
        width: 100%;
        position: absolute;
        cursor: row-resize;
        opacity: .5;
        -webkit-transition: opacity .5s;
        -o-transition: opacity .5s;
        transition: opacity .5s;
      }

      .startduck-ai-chat-widget-foot {
        border-top: 1px solid var(--sdw-widget-foot-border-color);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px 0 0 0;

        .startduck-ai-chat-widget-foot-container {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          -ms-flex-negative: 1;
          flex-shrink: 1;
          padding: 0 16px;

          .startduck-ai-chat-widget-foot-textarea {
            margin: 0 5px 0 0;
            padding: 0 5px 0 0;
            width: 100%;
            min-width: auto;
            min-height: auto;
            border: none;
            background: transparent;
            color: var(--sdw-widget-foot-text-color);
            font: 14px var(--sdw-font-family);
            resize: none;
            outline: none;
            scrollbar-width: thin;
            scrollbar-color: var(--sdw-scrollbar-colors);

            &:focus {
              &::-webkit-input-placeholder,
              &::placeholder {
                opacity: .5;
                -webkit-transition: opacity .3s;
                -o-transition: opacity .3s;
                transition: opacity .3s;
              }
            }
          }

          .startduck-ai-chat-widget-foot-button {
            opacity: 1;
            display: block;
            width: 33px;
            height: 33px;
            background-color: var(--sdw-widget-foot-sent-button-color);
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("");
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            -webkit-appearance: none;
            outline: none;
            border-radius: var(--sdw-widget-foot-sent-button-border-radius);
            -ms-flex-negative: 0;
            flex-shrink: 0;
            border-color: transparent;

            &:hover {
              background-color: var(--sdw-widget-foot-sent-button-color);
            }

            &.active {
              opacity: .85;
              cursor: pointer;
              background-color: var(--sdw-widget-foot-sent-button-active-color);

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .startduck-ai-chat-widget-copyright {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 23px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 23px;
      flex: 0 0 23px;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: var(--sdw-widget-copyright-padding);
      width: 100%;
      max-width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .06);
      box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .06);
      background-color: var(--sdw-widget-copyright-background);
      position: relative;
      font-size: var(--sdw-widget-copyright-text-size);

      a, a:hover, a:visited {
        color: var(--sdw-widget-copyright-text-color);
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        svg,
        img {
          margin: 0 3px;
          opacity: 1;
          width: var(--sdw-widget-copyright-image-width);
        }
      }
    }
  }
}

.startduck-ai-chat-widget-double-wrapper {
  width: fit-content;
  height: fit-content;

  -webkit-box-shadow: var(--sdw-widget-inline-box-shadow);
  box-shadow: var(--sdw-widget-inline-box-shadow);
  border: var(--sdw-widget-border-size) solid var(--sdw-widget-border-color);
  border-radius: var(--sdw-widget-inline-border-radius);
  padding: var(--sdw-widget-inline-padding);

  .startduck-ai-chat-widget-wrapper {
    position: static;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}