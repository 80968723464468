﻿/*
 * Chat button btn
 */
.startduck-ai-chat-button-wrapper {
  position: fixed;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  visibility: hidden;
  direction: ltr;
  z-index: 99998;
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;

  &.startduck-ai-chat-button-visible {
    visibility: visible;
    -webkit-animation: startduck-ai-button-visible 1s ease-out forwards 1;
    animation: startduck-ai-button-visible 1s ease-out forwards 1;
  }

  .startduck-ai-chat-button-popup {
    display: none;
    position: absolute;
    padding: 12px 20px 12px 14px;
    width: var(--sdw-button-popup-width);
    border: 2px solid var(--sdw-button-popup-border-color);
    background: var(--sdw-button-popup-background);
    border-radius: 15px;
    box-sizing: border-box;
    z-index: 1;
    cursor: pointer;

    &.startduck-ai-chat-button-popup-show {
      display: block;
      -webkit-animation: show .4s linear forwards;
      animation: show .4s linear forwards;
    }

    .startduck-ai-chat-button-popup-btn-hide {
      position: absolute;
      top: 4px;
      right: 4px;
      display: inline-block;
      height: 20px;
      width: 20px;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23525C68%22%20fill-rule%3D%22evenodd%22%20d%3D%22M6.41%205.07l2.867-2.864-1.34-1.34L5.07%203.73%202.207.867l-1.34%201.34L3.73%205.07.867%207.938l1.34%201.34L5.07%206.41l2.867%202.867%201.34-1.34L6.41%205.07z%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center;
      opacity: .2;
      -webkit-transition: opacity .3s;
      transition: opacity .3s;
      cursor: pointer;
    }

    .startduck-ai-chat-button-popup-inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;

      .startduck-ai-chat-button-popup-image {
        margin: 0 10px 0 0;
        width: var(--sdw-button-popup-image-width-height);
        text-align: center;

        .startduck-ai-chat-button-popup-image-item {
          display: inline-block;
          width: var(--sdw-button-popup-image-width-height);
          height: var(--sdw-button-popup-image-width-height);
          border-radius: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }

      .startduck-ai-chat-button-popup-content {
        flex-shrink: 1;
        flex-grow: 1;

        .startduck-ai-chat-button-popup-name {
          font: bold 14px var(--sdw-font-family);
          color: var(--sdw-button-popup-name-color);
        }

        .startduck-ai-chat-button-popup-description {
          font: 13px var(--sdw-font-family);
          color: var(--sdw-button-popup-description-color);
        }
      }
    }

    .startduck-ai-chat-button-popup-triangle {
      position: absolute;
      display: block;
      width: 8px;
      height: 8px;
      background: var(--sdw-button-popup-background);
      border-right: 2px solid var(--sdw-button-popup-border-color);
      border-bottom: 2px solid var(--sdw-button-popup-border-color);
      bottom: 25px;
      right: -6px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .startduck-ai-chat-button-social {
    display: none;

    .startduck-ai-chat-button-social-item {
      position: relative;
      display: block;
      margin: 0 8px 8px 0;
      width: 45px;
      height: 45px;
      background-size: 100%;
      border-radius: 25px;
      -webkit-box-shadow: 0 8px 6px -6px rgba(33, 33, 33, .2);
      -moz-box-shadow: 0 8px 6px -6px rgba(33, 33, 33, .2);
      box-shadow: 0 8px 6px -6px rgba(33, 33, 33, .2);
      cursor: pointer;
      background-color: var(--sdw-button-social-background);

      &.startduck-ai-chat-button-social-chat {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
      }

      &:hover {
        -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
        box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
        -webkit-transition: box-shadow .17s cubic-bezier(0, 0, .2, 1);
        transition: box-shadow .17s cubic-bezier(0, 0, .2, 1);

        .startduck-ai-chat-button-social-tooltip {
          left: 50px;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          opacity: 1;
          z-index: 1;
        }
      }
    }

    .startduck-ai-chat-button-social-tooltip {
      position: absolute;
      top: 50%;
      left: -9000px;
      display: inline-block;
      padding: 5px 10px;
      max-width: 360px;
      border-radius: 10px;
      font: 13px / 15px var(--sdw-font-family);
      color: #000;
      background: #fff;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .startduck-ai-chat-button-inner-container {
    display: inline-block;
    -webkit-transform: scale(.85);
    transform: scale(.85);
    -webkit-transition: transform .3s;
    transition: transform .3s;
    position: relative;
    border-radius: 50%;

    .startduck-ai-chat-button-inner-mask {
      position: absolute;
      left: var(--sdw-button-mask-size);
      top: var(--sdw-button-mask-size);
      height: calc(var(--sdw-button-width-height) - var(--sdw-button-mask-size) * 2);
      width: calc(var(--sdw-button-width-height) - var(--sdw-button-mask-size) * 2);
      border-radius: 100px;
      background: var(--sdw-button-mask-background);
      opacity: var(--sdw-button-mask-opacity);
    }

    .startduck-ai-chat-button-block {
      width: var(--sdw-button-width-height);
      height: var(--sdw-button-width-height);
      border-radius: 100%;
      box-sizing: border-box;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;

      .startduck-ai-chat-button-pulse {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid var(--sdw-button-pulse-border-color);
        border-radius: 50%;

        &.startduck-ai-chat-button-pulse-animate {
          animation: startduck-ai-button-pulse infinite 1.5s;
        }
      }

      .startduck-ai-chat-button-inner-block {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: var(--sdw-button-width-height);
        border-radius: 100px;
        background: var(--sdw-button-background);
        box-sizing: border-box;

        .startduck-ai-chat-button-close {
          display: none;
        }

        .startduck-ai-chat-button-icon-container {
          position: relative;
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }

        .startduck-ai-chat-button-inner-item {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          -webkit-transition: opacity .6s ease-out;
          transition: opacity .6s ease-out;
          -webkit-animation: startduck-ai-button-rotate .4s;
          animation: startduck-ai-button-rotate .4s;
          opacity: 0;
          overflow: hidden;
          box-sizing: border-box;

          &.startduck-ai-chat-button-icon-animation {
            opacity: 1;
          }

          svg, img {
            width: var(--sdw-button-chat-image-width);
            height: var(--sdw-button-chat-image-height);
            color: var(--sdw-button-chat-image-color);
          }
        }
      }
    }
  }

  &.startduck-ai-button-top,
  &.startduck-ai-button-bottom {
    .startduck-ai-chat-button-inner-container .startduck-ai-chat-button-inner-mask {
      background: #d6d6d6 !important;
      -webkit-transition: background .3s linear;
      transition: background .3s linear;
    }

    .startduck-ai-chat-button-social {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-line-pack: end;
      align-content: flex-end;
      height: -webkit-calc(100vh - 110px);
      height: calc(100vh - 110px);
      -webkit-animation: startduck-ai-button-bottom-open .3s;
      animation: startduck-ai-button-bottom-open .3s;
      visibility: visible;
    }

    .startduck-ai-chat-button-pulse {
      display: none;
    }

    .startduck-ai-chat-button-inner-container .startduck-ai-chat-button-block .startduck-ai-chat-button-inner-block .startduck-ai-chat-button-inner-item {
      display: none;
      outline: 1px solid red;

      &.startduck-ai-chat-button-close {
        display: flex;
        -webkit-animation: startduck-ai-chat-social .4s;
        animation: startduck-ai-chat-social .4s;
        opacity: 1;

        svg, img {
          width: var(--sdw-button-close-image-width);
          height: var(--sdw-button-close-image-height);
          color: var(--sdw-button-close-image-color);
        }
      }
    }
  }

  &.startduck-ai-button-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;

    .startduck-ai-chat-button-social {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 10px 0 0 0;
    -webkit-animation: startduck-ai-button-top-open .3s;
    animation: startduck-ai-button-top-open .3s;
    }
  }
}

@media (max-height: 768px) {
  .startduck-ai-chat-button-wrapper.startduck-ai-button-top .startduck-ai-chat-button-social, .startduck-ai-chat-button-wrapper.startduck-ai-button-bottom .startduck-ai-chat-button-social {
    max-height: 600px;
  }
}

@media (max-height: 667px) {
  .startduck-ai-chat-button-wrapper.startduck-ai-button-top .startduck-ai-chat-button-social, .startduck-ai-chat-button-wrapper.startduck-ai-button-bottom .startduck-ai-chat-button-social {
    max-height: 440px;
  }
}

@media (max-height: 568px) {
  .startduck-ai-chat-button-wrapper.startduck-ai-button-top .startduck-ai-chat-button-social, .startduck-ai-chat-button-wrapper.startduck-ai-button-bottom .startduck-ai-chat-button-social {
    max-height: 380px;
  }
}

@media (max-height: 480px) {
  .startduck-ai-chat-button-wrapper.startduck-ai-button-top .startduck-ai-chat-button-social, .startduck-ai-chat-button-wrapper.startduck-ai-button-bottom .startduck-ai-chat-button-social {
    max-height: 335px;
  }
}
