﻿.startduck-ai-chat-flat-button-wrapper {
  position: fixed;
  width: var(--sdw-widget-width);
  max-width: calc(-80px + 100vw);
  height: 40px;
  max-height: 70px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  visibility: hidden;
  direction: ltr;
  z-index: 99998;
  -webkit-tap-highlight-color: transparent;
  border-radius: var(--sdw-button-flat-border-radius);
  /*border-top-left-radius: 10px;
  border-top-right-radius: 35px;*/
  /*overflow: hidden;*/
  border: 1px solid var(--sdw-widget-border-color);
  background: var(--sdw-widget-head-background);

  &.startduck-ai-chat-flat-button-visible {
    visibility: visible;
  }

  &.startduck-ai-chat-flat-button-show {
    animation: startduck-ai-chat-flat-button-show 300ms cubic-bezier(0.39, 0.24, 0.21, 0.99) 0s 1 normal both running;
  }

  &.startduck-ai-chat-flat-button-hide {
    animation: startduck-ai-chat-flat-button-hidden 300ms cubic-bezier(0.39, 0.24, 0.21, 0.99) 0s 1 normal both running;
  }

  .startduck-ai-chat-flat-button-social {
    display: none;
    position: absolute;
    cursor: pointer;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
  }

  .startduck-ai-chat-flat-button-social {
    background-color: #fff;
    color: #222d38;
    display: block;
    /*outline: 0;
    overflow: hidden;
    text-decoration: none;*/
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 17px 1px, rgba(0, 0, 0, 0.16) 0px 0px 4px;
    transform-origin: right top;
    transform: rotate(90deg) translate(-16px, calc(-100% + 280px));
    width: 280px;

    .startduck-ai-chat-flat-button-social-inner-container {
      /*position: relative;*/
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: center;
      box-sizing: border-box;

      .startduck-ai-chat-flat-button-social-inner-item {
        padding: 10px 5px;
        display: flex;
        height: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid red;
      }
    }
  }

  &:hover {

  }

  .startduck-ai-chat-flat-button-inner-container {
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .startduck-ai-chat-flat-button-wrap-logo {
      margin: 10px;
      display: flex;

      .startduck-ai-chat-flat-button-logo {
        width: var(--sdw-widget-head-operator-width-height);
        height: var(--sdw-widget-head-operator-width-height);
      }
    }

    .startduck-ai-chat-flat-button-text {
      font: 14px var(--sdw-font-family);
      margin: 0 8px 0 16px;
      color: rgb(240, 241, 241);
      flex-grow: 1;
      flex-shrink: 1;
      white-space: nowrap;
    }

    .startduck-ai-chat-flat-button-image {
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      margin-right: 10px;
    }

    .startduck-ai-chat-flat-button-light {
      align-self: start;
      width: 35px;
      height: 35px;
      border-radius: 0 35px;
      background: #ff7a1b;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-right: 0;
      border-top: 0;
    }
  }

  &.operator {
    height: 70px;

    .startduck-ai-chat-flat-button-inner-container {
      left: 8px;

      .startduck-ai-chat-flat-button-wrap-logo {
        position: relative;

        &.online .startduck-ai-chat-flat-button-online {
          width: 5px;
          height: 5px;
          position: absolute;
          right: 10px;
          z-index: 1;
          border-radius: 50%;
          background-color: #18c139;
          border: 2px solid rgb(47, 50, 74);
        }

        .startduck-ai-chat-flat-button-logo {
          border-radius: 50%;
        }

        &.startduck-ai-chat-flat-button-logo-blink {
          position: relative;
          border-radius: 50%;
          overflow: hidden;

          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 140%;
            height: 100%;
            top: 120%;
            left: -20%;
            opacity: 0.6;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0), #ffffff 40%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0));
            -webkit-transform: rotate(20deg) translateZ(0);
            -moz-transform: rotate(20deg) translateZ(0);
            -ms-transform: rotate(20deg) translateZ(0);
            -o-transform: rotate(20deg) translateZ(0);
            transform: rotate(20deg) translateZ(0);
            -webkit-animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
            -moz-animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
            -ms-animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
            -o-animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
            animation: startduck-ai-ava-blink 10s infinite cubic-bezier(0, 0.3, 1, 0.7);
            will-change: transform;
          }
        }
      }

      .startduck-ai-chat-flat-button-title {
        color: var(--sdw-widget-head-title-color);
        font: 25px var(--sdw-font-family);
        font-weight: 600;
        max-width: 100%;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
      }

      .startduck-ai-chat-flat-button-operator-container {
        font: 14px/18px var(--sdw-font-family);
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .startduck-ai-chat-flat-button-operator-name {
          color: var(--sdw-widget-head-operator-name-color);
          font: 15px/18px var(--sdw-font-family);
          margin-bottom: 4px;
        }

        .startduck-ai-chat-flat-button-operator-description {
          color: var(--sdw-widget-head-operator-description-color);
          font: 13px/14px var(--sdw-font-family);
          opacity: var(--sdw-widget-head-operator-description-opacity);
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

