.startduck-ai-button-shadow {
  position: fixed;
  background: rgba(33, 33, 33, .3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 10000;
}

.startduck-ai-button-social-hide {
  -webkit-animation: startduck-ai-social-hidden .3s linear forwards;
  animation: startduck-ai-social-hidden .3s linear forwards;
}

.startduck-ai-button-social-show {
  -webkit-animation: startduck-ai-social-show .3s cubic-bezier(.75, .01, .75, 0) forwards;
  animation: startduck-ai-social-show .3s cubic-bezier(.75, .01, .75, 0) forwards;
}

