﻿// ANIMATION

@-webkit-keyframes startduck-ai-social-hidden {
  0% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    opacity: 1;
  }
  99% {
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes startduck-ai-social-hidden {
  0% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    opacity: 1;
  }
  99% {
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-webkit-keyframes startduck-ai-social-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes startduck-ai-social-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes startduck-ai-button-top-open {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes startduck-ai-button-top-open {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes startduck-ai-button-bottom-open {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes startduck-ai-button-bottom-open {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes startduck-ai-button-visible {
  from {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  30.001% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }
  62.999% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes startduck-ai-button-visible {
  from {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  30.001% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }
  62.999% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes startduck-ai-button-pulse {
  50% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1
  }
  100% {
    -webkit-transform: scale(2, 2);
    transform: scale(2, 2);
    opacity: 0
  }
}

@keyframes startduck-ai-button-pulse {
  50% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1
  }
  100% {
    -webkit-transform: scale(2, 2);
    transform: scale(2, 2);
    opacity: 0
  }
}

@-webkit-keyframes startduck-ai-button-rotate {
  from {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@keyframes startduck-ai-button-rotate {
  from {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@-webkit-keyframes startduck-ai-chat-show {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes startduck-ai-chat-show {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes startduck-ai-chat-widget-container-show {
  0% {
    opacity: 0
  }
  30% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes startduck-ai-chat-widget-container-show {
  0% {
    opacity: 0
  }
  30% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@-webkit-keyframes startduck-ai-chat-widget-messages {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes startduck-ai-chat-widget-messages {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@-webkit-keyframes startduck-ai-chat-widget-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes startduck-ai-chat-widget-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes startduck-ai-chat-widget-loading {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

@keyframes startduck-ai-chat-widget-loading {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

@-webkit-keyframes startduck-ai-chat-flat-button-show {
  0% {
    height: 0
  }
  100% {
    height: 100%
  }
}

@keyframes startduck-ai-chat-flat-button-show {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes startduck-ai-chat-flat-button-hidden {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes startduck-ai-chat-social {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes startduck-ai-chat-social {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes startduck-ai-ava-blink {
  0% {
    top: 120%;
  }
  75% {
    top: 120%;
  }
  80% {
    top: -120%;
  }
  100% {
    top: -120%;
  }
}

@-webkit-keyframes startduck-ai-ava-blink {
  0% {
    top: 120%;
  }
  75% {
    top: 120%;
  }
  80% {
    top: -120%;
  }
  100% {
    top: -120%;
  }
}