﻿// TOP LEFT CORNER
.startduck-ai-chat-button-position-top-left {
  left: var(--sdw-button-left-position);
  top: var(--sdw-button-top-position);

  @at-root {
    .startduck-ai-chat-button-wrapper#{&},
    .startduck-ai-chat-flat-button-wrapper#{&} {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }

  &.startduck-ai-button-top .startduck-ai-chat-button-social {
    -ms-flex-line-pack: start;
    align-content: flex-start;

    .startduck-ai-chat-button-social-item {
      margin: 0 0 8px 8px;
    }
  }

  &.startduck-ai-chat-button-position-top {
    bottom: inherit;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &.startduck-ai-chat-button-position-left {
    right: inherit;
    -webkit-transform: rotate(90deg) translate(0, -100%);
    transform: rotate(90deg) translate(0, -100%);
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
  }

  .startduck-ai-chat-button-popup {
    top: 0;
    left: calc(var(--sdw-button-width-height) + 8px);

    .startduck-ai-chat-button-popup-triangle {
      top: 19px;
      left: -6px;
      -webkit-transform: rotate(134deg);
      transform: rotate(134deg);
    }
  }
}

/*.startduck-ai-chat-widget-position-top-left {
  left: var(--sdw-button-left-position);
  top: var(--sdw-button-top-position);

  @at-root {
    .startduck-ai-chat-widget-wrapper#{&} {
      -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
      transform-origin: top left;
      top: var(--sdw-button-top-position);
      left: var(--sdw-button-left-position);
    }
  }

  .startduck-ai-chat-widget-hide-btn {
    top: 0;
    right: -34px;
  }
}*/

// TOP RIGHT CORNER
.startduck-ai-chat-button-position-top-right {
  right: var(--sdw-button-right-position);
  top: var(--sdw-button-top-position);

  &.startduck-ai-chat-button-position-top {
    bottom: inherit;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &.startduck-ai-chat-button-position-right {
    left: inherit;
    -webkit-transform: rotate(-90deg) translate(0, -100%);
    transform: rotate(-90deg) translate(0, -100%);
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
  }

  .startduck-ai-chat-button-social .startduck-ai-chat-button-social-item:hover .startduck-ai-chat-button-social-tooltip {
    left: -5px;
    -webkit-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    opacity: 1;
  }

  .startduck-ai-chat-button-popup {
    top: 0;
    left: calc(-8px - var(--sdw-button-popup-width));

    .startduck-ai-chat-button-popup-triangle {
      top: 19px;
      right: -6px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

/*.startduck-ai-chat-widget-position-top-right {
  right: var(--sdw-button-right-position);
  top: var(--sdw-button-top-position);

  @at-root {
    .startduck-ai-chat-widget-wrapperr#{&} {
      -webkit-transform-origin: top right;
      -ms-transform-origin: top right;
      transform-origin: top right;
      top: var(--sdw-button-top-position);
      right: var(--sdw-button-right-position);
    }
  }

  .startduck-ai-chat-widget-hide-btn {
    top: 0;
    left: -34px;
  }
}*/

// BOTTOM LEFT CORNER
.startduck-ai-chat-button-position-bottom-left {
  left: var(--sdw-button-left-position);
  bottom: var(--sdw-button-bottom-position);

  @at-root {
    .startduck-ai-chat-button-wrapper#{&},
    .startduck-ai-chat-flat-button-wrapper#{&} {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }

  &.startduck-ai-button-bottom .startduck-ai-chat-button-social {
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .startduck-ai-chat-button-social-item {
      margin: 0 0 8px 8px;
    }
  }

  &.startduck-ai-chat-button-position-bottom {
    top: inherit;
  }

  &.startduck-ai-chat-button-position-left {
    right: inherit;
    -webkit-transform: rotate(90deg) translate(-100%, 0);
    transform: rotate(90deg) translate(-100%, 0);
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
  }

  .startduck-ai-chat-button-popup {
    left: calc(var(--sdw-button-width-height) + 8px);
    bottom: 0;

    .startduck-ai-chat-button-popup-triangle {
      bottom: 25px;
      left: -6px;
      -webkit-transform: rotate(134deg);
      transform: rotate(134deg);
    }
  }
}

.startduck-ai-chat-widget-position-bottom-left {
  left: var(--sdw-button-left-position);
  bottom: var(--sdw-button-bottom-position);

  @at-root {
    .startduck-ai-chat-widget-wrapper#{&} {
      -webkit-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
      transform-origin: bottom left;
      left: var(--sdw-button-left-position);
      bottom: var(--sdw-button-bottom-position);
    }
  }

  .startduck-ai-chat-widget-hide-btn {
    top: 0;
    right: -34px;
  }
}

// BOTTOM RIGHT CORNER
.startduck-ai-chat-button-position-bottom-right {
  right: var(--sdw-button-right-position);
  bottom: var(--sdw-button-bottom-position);

  @at-root {
    .startduck-ai-chat-button-wrapper#{&},
    .startduck-ai-chat-flat-button-wrapper#{&} {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: reverse;
      flex-direction: column-reverse;
    }
  }

  &.startduck-ai-button-bottom .startduck-ai-chat-button-social {
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    order: 1;
  }

  &.startduck-ai-chat-button-position-bottom {
    top: inherit;
  }

  &.startduck-ai-chat-button-position-right {
    right: var(--sdw-button-right-position);
    left: inherit;
    -webkit-transform: rotate(-90deg) translate(100%, 0);
    transform: rotate(-90deg) translate(100%, 0);
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
  }

  .startduck-ai-chat-button-social .startduck-ai-chat-button-social-item:hover .startduck-ai-chat-button-social-tooltip {
    left: -5px;
    -webkit-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    opacity: 1;
  }

  .startduck-ai-chat-button-popup {
    left: calc(-8px - var(--sdw-button-popup-width));
    bottom: 0;
  }

  .startduck-ai-chat-button-popup-triangle {
    bottom: 25px;
    right: -6px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.startduck-ai-chat-widget-position-bottom-right {
  right: var(--sdw-button-right-position);
  bottom: var(--sdw-button-bottom-position);

  @at-root {
    .startduck-ai-chat-widget-wrapper#{&} {
      -webkit-transform-origin: bottom right;
      -ms-transform-origin: bottom right;
      transform-origin: bottom right;
      right: var(--sdw-button-right-position);
      bottom: var(--sdw-button-bottom-position);
    }
  }

  .startduck-ai-chat-widget-hide-btn {
    top: 0;
    left: -34px;
  }
}

.startduck-ai-chat-button-position-center {
  &.startduck-ai-chat-button-position-bottom {
    top: inherit;
    transform: translateX(-50%);
    left: 50%;
    right: inherit;
    bottom: var(--sdw-button-bottom-position);
  }

  &.startduck-ai-chat-button-position-right {
    left: inherit;
    right: var(--sdw-button-right-position);
    bottom: 50%;
    -webkit-transform: rotate(-90deg) translate(50%, 0);
    transform: rotate(-90deg) translate(50%, 0);
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
  }

  &.startduck-ai-chat-button-position-left {
    right: inherit;
    left: var(--sdw-button-left-position);
    bottom: 50%;
    -webkit-transform: rotate(90deg) translate(-50%, 0);
    transform: rotate(90deg) translate(-50%, 0);
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}